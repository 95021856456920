import React from 'react';
import PropTypes from 'prop-types';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import { createBrowserHistory } from "history";


import { BigMap } from '../../pages/BigMap/BigMap';

import { Report } from '../../pages/Report/Report';

import { Header } from '../../organisms/Header/Header';

import './MenuView.scss';


// export const MenuView = ({logged_in}) => {

export class MenuView extends React.Component {

    constructor({id, logged_in}) {
        super();
        this.id = id;
        this.history = createBrowserHistory();
        this.logged_in = logged_in;

        this.not_logged_in_routes  = <Routes>
            <Route path="/" element={
                <div className="projectslist_area">
                    <Report ref={instance => { this.child = instance; }} is_edit_form={false} check_track_id={false}/>
                </div>
            }/>
            <Route path="/track/:track_id" element={
                <div className="projectslist_area">
                    <BigMap ref={instance => { this.child = instance; }} check_track_id={true}/>
                </div>
            }/>
            <Route path="/add" element={
                <div className="projectslist_area">
                    <BigMap ref={instance => { this.child = instance; }} is_edit_form={true}/>
                </div>
            }/>
        </Routes>

        this.logged_in_routes = <Routes>
            {/* <Route path="/" element={
                <div className="projectslist_area">
                    <Landing />
                </div>
            }/>
            <Route path="/list/" element={
                <div className="projectslist_area">
                    <LoginWindow id={"login_window"}/>
                </div>
            }/>
            <Route path="/login/" element={
                <div className="projectslist_area">
                    <LoginWindow id={"login_window"}/>
                </div>
            }/> */}
        </Routes>

    }

    locationChangeTrigger = () => {
        this.child.openTrackAddForm()
    }

    

    render = () => {
        return(
            <div className="menu_view">
                <Router>
                    {/* <div className="header_area">
                        <Header locationChangeTrigger={this.locationChangeTrigger}/>
                    </div> */}
                    <div>
                        {
                            this.logged_in 
                            ? this.logged_in_routes
                            : this.not_logged_in_routes
                        }
                    </div>
                </Router>
            </div>
        );
    }
}
